import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import AppCards from "../../components/appCards";
import SectionTitle from "../../components/sectionTitle";

const AppsPage = () => {
    const {allAppsYaml} = useStaticQuery(
        graphql`
          query {
            allAppsYaml {
              edges {
                node {
                  id
                  name
                  description
                  image
                  store {
                    appstore
                    googleplay
                  }
                }
              }
            }
           }
    `
    )
    const apps = allAppsYaml.edges.map((e) => e.node)
    return (
        <Layout isTop={false}>
            <Seo title="配信アプリ"
                 description="nullhouseの配信アプリ一覧です。"/>
            <SectionTitle title="配信アプリ"/>
            <AppCards apps={apps}/>
        </Layout>
    )
}

export default AppsPage
